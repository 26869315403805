import { Connection, Keypair, PublicKey, SystemProgram, TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import bs58 from 'bs58';
import { useJito } from './useJito';

export const useSolana = () => {
    const { sendBundle } = useJito();
    async function getMultipleSolBalances(connection: Connection, publicKeys: PublicKey[]): Promise<number[]> {
        //slice for groups of 100 public keys
        const balances: number[] = [];
        for (let i = 0; i < publicKeys.length; i += 100) {
            const localPublicKeys = publicKeys.slice(i, i + 100);
            const accountInfos = await connection.getMultipleAccountsInfo(localPublicKeys, 'processed');
            for (const accountInfo of accountInfos) {
                if (accountInfo === null) {
                    balances.push(0);
                    continue;
                }
                const lamports = accountInfo.lamports;
                if (lamports === undefined || lamports === undefined) {
                    balances.push(0);
                } else balances.push(lamports);
            }
        }
        return balances;
    }

    async function multipleSolanaTrasfersWithJito(
        connection: Connection,
        feePayer: Keypair,
        froms: Keypair[],
        tos: PublicKey[],
        amounts: number[],
        jitoTipLamports: number,
        logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void,
    ) {
        console.log('tip', jitoTipLamports);
        console.log('feePayer', feePayer.publicKey.toBase58());
        //remove all wallets with 0 amount to send
        const walletsToSend = froms.filter((from, index) => amounts[index] > 0);
        const walletsToReceive = tos.filter((to, index) => amounts[index] > 0);
        const amountsToSend = amounts.filter((amount, index) => amount > 0);

        //remove elements if receiver and sender are the same
        for (let i = 0; i < walletsToReceive.length; i++) {
            if (walletsToSend[i].publicKey.equals(walletsToReceive[i])) {
                console.log('removing', walletsToSend[i].publicKey.toBase58());
                walletsToSend.splice(i, 1);
                walletsToReceive.splice(i, 1);
                amountsToSend.splice(i, 1);
                i--;
            }
        }
        console.log(
            walletsToSend.map((wallet) => wallet.publicKey.toBase58()),
            walletsToReceive.map((wallet) => wallet.toBase58()),
            amountsToSend,
        );
        if (walletsToSend.length === 0) {
            return [];
        }

        const instructions = walletsToReceive.map((to, index) =>
            SystemProgram.transfer({
                fromPubkey: walletsToSend[index].publicKey,
                toPubkey: to,
                lamports: amountsToSend[index],
            }),
        );

        const instructionsPerTransaction = 8;
        const transactionsPerBundle = 4;

        let transactions: VersionedTransaction[] = [];

        for (let i = 0; i < instructions.length; i += instructionsPerTransaction) {
            const { blockhash } = await connection.getLatestBlockhash();
            const message = new TransactionMessage({
                payerKey: feePayer.publicKey,
                recentBlockhash: blockhash,
                instructions: instructions.slice(i, i + instructionsPerTransaction),
            }).compileToV0Message();

            const signers = walletsToSend.slice(i, i + instructionsPerTransaction);
            const transaction = new VersionedTransaction(message);
            transaction.sign(signers);
            transaction.sign([feePayer]);
            transactions.push(transaction);

            if (transactions.length === transactionsPerBundle || i + instructionsPerTransaction >= instructions.length) {
                const id = await sendBundle(connection, transactions, feePayer, jitoTipLamports);
                logMessage(`Sent ${transactions.length} transactions with bundle id: ${id}`, 'info', 'jito', id);
                const signature = bs58.encode(transactions[0].signatures[0]);
                const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
                await connection.confirmTransaction(
                    { blockhash: blockhash, lastValidBlockHeight: lastValidBlockHeight, signature: signature },
                    'confirmed',
                );
                transactions = [];
            }
        }
    }

    return { getMultipleSolBalances, multipleSolanaTrasfersWithJito };
};
