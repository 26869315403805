async function parseCsv(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const csv = event.target?.result as string;

            try {
                const rows = csv
                    .trim()
                    .split('\n')
                    .slice(1)
                    .map((row) => row.trim().split(','));

                resolve(rows);
            } catch {
                new Error('File reading error: No result found.');
            }
        };

        reader.onerror = function (error) {
            console.error('File reading error: ', error);
        };

        reader.readAsText(file);
    });
}

export default parseCsv;
