import { NavLink } from 'react-router-dom';

import NavbarCSS from '../styles/Navbar.module.css';
import { useUrlParams } from '../hooks/useUrlParams';

export function Navbar() {
    const { role, sessionId } = useUrlParams();

    return (
        <nav>
            <ul>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.settings}`}
                        to={`settings?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Settings</span>
                        <hr className={NavbarCSS.decoration} />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.buySell}`}
                        to={`/&?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Buy/Sell</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.test2}`}
                        to={`empty?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Empty</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.fundsManager}`}
                        to={`manager?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Funds Manager</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.tokenCreator}`}
                        to={`create?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Token Creator</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`${NavbarCSS.container} ${NavbarCSS.console}`}
                        to={`console?role=${role}&sessionId=${sessionId}`}
                    >
                        <span className={NavbarCSS.icon}></span>
                        <span className={NavbarCSS.text}>Console</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
