import { LoggerType } from '../utils/types';
import ConsoleCSS from '../styles/Console.module.css';

export function Console({ messages }: { messages: LoggerType[] }) {
    return (
        <section className={ConsoleCSS.container}>
            <ul>
                {messages.map((message, index) => (
                    <li
                        key={index}
                        className={
                            message.type === 'success'
                                ? `${ConsoleCSS.success}`
                                : message.type === 'error'
                                ? `${ConsoleCSS.error}`
                                : ''
                        }
                    >
                        {message.message}
                        {message.url === 'jito' && message.transaction && (
                            <>
                                {' - '}
                                <a
                                    href={`https://explorer.jito.wtf/bundle/${message.transaction}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    View on JITO Explorer
                                </a>
                            </>
                        )}
                        {message.url === 'solscan' && message.transaction && (
                            <>
                                {' - '}
                                <a
                                    href={`https://solscan.io/tx/${message.transaction}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    View on Solscan
                                </a>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
}
