import { useState, useCallback } from 'react';

export function useClipboard() {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const copyToClipboardFallback = (text: string) => {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();

        try {
            return document.execCommand('copy');
        } catch (error) {
            console.warn('Copy to Clipboard Fallback failed', error);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    };

    const copyToClipboard = useCallback(async (text: string) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard API not available');
            return copyToClipboardFallback(text);
        }

        try {
            await navigator.clipboard.writeText(text);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
            return true;
        } catch (error) {
            console.warn('Copy failed', error);
            return false;
        }
    }, []);

    return { isCopied, copyToClipboard };
}
