import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Connection, Keypair } from '@solana/web3.js';
import { LoggerType } from './Components/utils/types';
import getDateString from './Components/utils/get-date-string';

// Pages
import { Navbar } from './Components/pages/Navbar';
import { Header } from './Components/pages/Header';
import { Settings } from './Components/pages/Settings';
import { BuySell } from './Components/pages/BuySell';
import { FundsManager } from './Components/pages/FundsManager';
import { TokenCreator } from './Components/pages/TokenCreator';
import { Console } from './Components/pages/Console';

// Styles
import './styles.css';
import './reset.css';
import 'react-toastify/dist/ReactToastify.css'; // Notification styles

export enum TokenStatus {
    NotLoaded = 'Not Loaded',
    Pump = 'Pump',
    CurveCompleted = 'Curve Completed',
    Raydium = 'Raydium',
}

function App() {
    const location = useLocation();

    // CSV States
    const [deployers, setDeployers] = useState<Keypair[]>([]);
    const [buyers, setBuyers] = useState<Keypair[]>([]);
    const [mix, setMix] = useState<Keypair[]>([]);
    const [allWalletRoles, setAllWalletRoles] = useState<string[]>([]);
    const [buyerRoles, setBuyerRoles] = useState<string[]>([]);
    const [walletPublicKeys, setWalletPublicKeys] = useState<string[]>([]);
    const [solBuys, setSolBuys] = useState<number[]>([]);
    const [delays, setDelays] = useState<number[]>([]);
    const [slippages, setSlippages] = useState<number[]>([]);
    const [jitoTips, setJitoTips] = useState<number[]>([]);
    const [tokenBalances, setTokenBalances] = useState<number[]>([]);
    const [solValues, setSolValues] = useState<number[]>([]);
    const [totalInitBalance, setTotalInitBalance] = useState<number>(0);

    const [tokenStatus, setTokenStatus] = useState<TokenStatus>(TokenStatus.NotLoaded);
    const [tokenMint, setTokenMint] = useState<string>('');
    const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);
    const [bondingCurve, setBondingCurve] = useState<string | undefined>(undefined);
    const [associatedBondingCurve, setAssociatedBondingCurve] = useState<string | undefined>(undefined);
    const [ammPool, setAmmPool] = useState<string | undefined>(undefined);
    const [tokenName, setTokenName] = useState<string>('');
    const [tokenSymbol, setTokenSymbol] = useState<string>('');

    const availableNodes = ['Custom'];
    const [nodeMode, setNodeMode] = useState<string>(availableNodes[0]);
    const [customHttp, setCustomHttp] = useState<string>(process.env.REACT_APP_SOLANA_HTTP_URL1!);
    const [customWs, setCustomWs] = useState<string>(process.env.REACT_APP_SOLANA_WS_URL1!);
    const availableJitoBlockengines = ['Amsterdam'];
    const [jitoBlockengine, setJitoBlockengine] = useState<string>(availableJitoBlockengines[0]);
    const [defaultSellPercent, setDefaultSellPercent] = useState<number>(25);
    const [rpcTxEnabled, setRpcTxEnabled] = useState<boolean>(false);
    const [rpcTxPriorityFee, setRpcTxPriorityFee] = useState<number>(0.01);
    const [jitoEnabled, setJitoEnabled] = useState<boolean>(true);
    const [jitoPriorityFee, setJitoPriorityFee] = useState<number>(0.001);

    const defaultSettings = {
        nodeMode: availableNodes[0],
        customHttp: process.env.REACT_APP_SOLANA_HTTP_URL1!,
        customWs: process.env.REACT_APP_SOLANA_WS_URL1!,
        jitoBlockengine: availableJitoBlockengines[0],
        defaultSellPercent: 25,
        rpcTxEnabled: false,
        rpcTxPriorityFee: 0.01,
        jitoEnabled: true,
        jitoPriorityFee: 0.001,
    };

    const [connection, setConnection] = useState<Connection>(
        new Connection(process.env.REACT_APP_SOLANA_HTTP_URL1!, {
            wsEndpoint: process.env.REACT_APP_SOLANA_WS_URL1,
        }),
    );

    const [messages, setMessages] = useState<LoggerType[]>([]);
    const logMessage = (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => {
        message = `${getDateString()} - ${message}`;
        setMessages((prevMessages) => [...prevMessages, { message, type, url, transaction }]);
    };

    return (
        <div className='container'>
            <nav className='navigation'>
                <Navbar />
            </nav>
            <main className='content'>
                {location.pathname !== '/settings' && (
                    <Header
                        tokenMint={tokenMint}
                        tokenLoaded={tokenLoaded}
                        tokenName={tokenName}
                        tokenSymbol={tokenSymbol}
                        setDeployers={setDeployers}
                        setMix={setMix}
                        wallets={buyers}
                        setWallets={setBuyers}
                        setAllWalletRoles={setAllWalletRoles}
                        setWalletPublicKeys={setWalletPublicKeys}
                        setDelays={setDelays}
                        setSlippages={setSlippages}
                        setJitoTips={setJitoTips}
                        setSolBuys={setSolBuys}
                        setTokenBalances={setTokenBalances}
                        setSolValues={setSolValues}
                        setTotalInitBalance={setTotalInitBalance}
                        connection={connection}
                        logMessage={logMessage}
                        setBuyerRoles={setBuyerRoles}
                    />
                )}
                <Routes>
                    <Route
                        path='settings'
                        element={
                            <Settings
                                nodeMode={nodeMode}
                                setNodeMode={setNodeMode}
                                setCustomHttp={setCustomHttp}
                                setCustomWs={setCustomWs}
                                setJitoBlockengine={setJitoBlockengine}
                                setDefaultSellPercent={setDefaultSellPercent}
                                availableNodes={availableNodes}
                                customHttp={customHttp}
                                customWs={customWs}
                                jitoBlockengine={jitoBlockengine}
                                availableJitoBlockengines={availableJitoBlockengines}
                                defaultSellPercent={defaultSellPercent}
                                setConnection={setConnection}
                                rpcTxEnabled={rpcTxEnabled}
                                setRpcTxEnabled={setRpcTxEnabled}
                                rpcTxPriorityFee={rpcTxPriorityFee}
                                setRpcTxPriorityFee={setRpcTxPriorityFee}
                                jitoEnabled={jitoEnabled}
                                setJitoEnabled={setJitoEnabled}
                                jitoPriorityFee={jitoPriorityFee}
                                setJitoPriorityFee={setJitoPriorityFee}
                                defaultSettings={defaultSettings}
                            />
                        }
                    />
                    <Route
                        path='/'
                        element={
                            <BuySell
                                connection={connection}
                                defaultSellPercent={defaultSellPercent}
                                wallets={buyers}
                                setWallets={setBuyers}
                                walletPublicKeys={walletPublicKeys}
                                setWalletPublicKeys={setWalletPublicKeys}
                                solBuys={solBuys}
                                setSolBuys={setSolBuys}
                                delays={delays}
                                setDelays={setDelays}
                                slippages={slippages}
                                setSlippages={setSlippages}
                                jitoTips={jitoTips}
                                setJitoTips={setJitoTips}
                                solValues={solValues}
                                setSolValues={setSolValues}
                                tokenMint={tokenMint}
                                setTokenMint={setTokenMint}
                                tokenLoaded={tokenLoaded}
                                setTokenLoaded={setTokenLoaded}
                                setTokenName={setTokenName}
                                setTokenSymbol={setTokenSymbol}
                                bondingCurve={bondingCurve}
                                setBondingCurve={setBondingCurve}
                                associatedBondingCurve={associatedBondingCurve}
                                setAssociatedBondingCurve={setAssociatedBondingCurve}
                                tokenBalances={tokenBalances}
                                setTokenBalances={setTokenBalances}
                                deployers={deployers}
                                setDeployers={setDeployers}
                                setMix={setMix}
                                jitoEnabled={jitoEnabled}
                                jitoPriorityFee={jitoPriorityFee}
                                rpcTxEnabled={rpcTxEnabled}
                                rpcTxPriorityFee={rpcTxPriorityFee}
                                logMessage={logMessage}
                                allWalletRoles={allWalletRoles}
                                setAllWalletRoles={setAllWalletRoles}
                                totalInitBalance={totalInitBalance}
                                tokenStatus={tokenStatus}
                                setTokenStatus={setTokenStatus}
                                ammPool={ammPool}
                                setAmmPool={setAmmPool}
                            />
                        }
                    />
                    <Route
                        path='manager'
                        element={
                            <FundsManager
                                connection={connection}
                                deployers={deployers}
                                buyers={buyers}
                                mix={mix}
                                jitoTips={jitoTips}
                                solBuys={solBuys}
                                tokenMint={tokenMint}
                                tokenLoaded={tokenLoaded}
                                tokenName={tokenName}
                                tokenSymbol={tokenSymbol}
                                logMessage={logMessage}
                                allWalletRoles={allWalletRoles}
                            />
                        }
                    />
                    <Route
                        path='create'
                        element={
                            <TokenCreator
                                connection={connection}
                                deployers={deployers}
                                jitoTips={jitoTips}
                                buyers={buyers}
                                buyAmounts={solBuys}
                                slippages={slippages}
                                logMessage={logMessage}
                                setTokenStatus={setTokenStatus}
                                setTokenMint={setTokenMint}
                                setTokenName={setTokenName}
                                setTokenSymbol={setTokenSymbol}
                                setTokenLoaded={setTokenLoaded}
                                setBondingCurve={setBondingCurve}
                                setAssociatedBondingCurve={setAssociatedBondingCurve}
                                buyerRoles={buyerRoles}
                                delays={delays}
                            />
                        }
                    />
                    <Route path='console' element={<Console messages={messages} />} />
                </Routes>
                <ToastContainer position='bottom-right' theme='dark' />
            </main>
        </div>
    );
}

export default App;
