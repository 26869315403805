export default function getDateString(): string {
    const currentDate: Date = new Date();

    const hours: string = currentDate.getHours().toString().padStart(2, '0');
    const minutes: string = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds: string = currentDate.getSeconds().toString().padStart(2, '0');
    const milliseconds: string = currentDate.getMilliseconds().toString().padStart(3, '0');

    const formattedDate: string = `${hours}:${minutes}:${seconds}.${milliseconds}`;

    return formattedDate;
}
