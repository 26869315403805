import solLogo from '../images/solLogo.png';
import TokenCreatorCSS from '../styles/TokenCreator.module.css';

import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    AddressLookupTableAccount,
    AddressLookupTableProgram,
    ComputeBudgetProgram,
    Connection,
    Context,
    Keypair,
    LAMPORTS_PER_SOL,
    PublicKey,
    SignatureReceivedNotification,
    SignatureStatusNotification,
    SystemProgram,
    SYSVAR_RENT_PUBKEY,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    getAssociatedTokenAddressSync,
    createAssociatedTokenAccountInstruction,
    TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { AnchorProvider, BN, Program } from '@coral-xyz/anchor';
import bs58 from 'bs58';
import { toast } from 'react-toastify';
import { usePump } from '../hooks/usePump';
import MyWallet from '../utils/anchor-wallet';
import { useJito } from '../hooks/useJito';
import { Bundle, CreateAndBuyBundleFactory, createTipInstruction } from '@sodamnfoolish/jito-ts';
import { PvmbApi, PumpFun, PumpFunApi } from '../utils';
import { TokenStatus } from '../../App';
import { countRoles } from '../utils/role-formatting';
import { Semaphore } from 'async-mutex';
import { sliceWalletAddress } from '../utils/role-formatting';
import pumpProgramIdl from '../utils/pump-program-idl';
import { useUrlParams } from '../hooks/useUrlParams';
import { useFeedGeteway } from '../hooks/useFeedGateway';

const BUYERS_WALLET_LIMIT = 24;
export const REMOTE_TOKEN_DEPLOY = 'deploy-token';

interface TokenCreatorProps {
    connection: Connection;
    deployers: Keypair[];
    jitoTips: number[];
    buyers: Keypair[];
    buyAmounts: number[];
    slippages: number[];
    delays: number[];
    logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void;
    setTokenStatus: Dispatch<SetStateAction<TokenStatus>>;
    setTokenMint: Dispatch<SetStateAction<string>>;
    setTokenName: Dispatch<SetStateAction<string>>;
    setTokenSymbol: Dispatch<SetStateAction<string>>;
    setTokenLoaded: Dispatch<SetStateAction<boolean>>;
    setBondingCurve: Dispatch<SetStateAction<string | undefined>>;
    setAssociatedBondingCurve: Dispatch<SetStateAction<string | undefined>>;
    buyerRoles: string[];
}

enum MintAddressOption {
    Pump = 'pump',
    Custom = 'custom',
    Random = 'random',
}

export function TokenCreator({
    connection,
    deployers,
    jitoTips,
    buyers,
    buyAmounts,
    slippages,
    delays,
    logMessage,
    setTokenStatus,
    setTokenMint,
    setTokenName,
    setTokenSymbol,
    setTokenLoaded,
    setBondingCurve,
    setAssociatedBondingCurve,
    buyerRoles,
}: TokenCreatorProps) {
    const { sendBundle } = useJito();
    const { getExchangeRate } = usePump();
    const { role, sessionId } = useUrlParams();
    const { feedGetewayClient } = useFeedGeteway();

    const [name, setName] = useState<string>('');
    const [tokenTicker, setTokenTicker] = useState<string>('');
    const [tokenDescription, setTokenDescription] = useState<string>('');
    const [tokenImage, setTokenImage] = useState<File | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tokenImagePreview, setTokenImagePreview] = useState<string | null>(null);
    const [twitterLink, setTwitterLink] = useState<string>('');
    const [telegramLink, setTelegramLink] = useState<string>('');
    const [website, setWebsite] = useState<string>('');
    const [tokenQuantity, setTokenQuantity] = useState<number>(0);
    // const [toggleBuy, setToggleBuy] = useState<boolean>(false);
    const [chooseDeployer, setChooseDeployer] = useState<MintAddressOption>(MintAddressOption.Pump);
    const [customWalletPrivateKey, setCustomWalletPrivateKey] = useState<string>('');
    const [selectedBuyerWallet, setSelectedBuyerWallet] = useState<boolean[]>([]);
    const [buyWithDelay, setBuyWithDelay] = useState<boolean>(false);
    const [selectWallets, setSelectWallets] = useState<boolean>(false);
    const [selectWalletsNumber, setSelectWalletsNumber] = useState<number>(BUYERS_WALLET_LIMIT);

    const countedRoles = countRoles(buyerRoles);

    const tokenNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const tokenTickerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTokenTicker(event.target.value);
    };

    const tokenDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTokenDescription(event.target.value);
    };

    const tokenImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setTokenImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);

            setTokenImage(file);

            const fileNameLabel = document.querySelector('.imageInput span');
            if (fileNameLabel) {
                fileNameLabel.textContent = file.name;
            }
        }
    };

    const twitterLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTwitterLink(event.target.value);
    };

    const telegramLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTelegramLink(event.target.value);
    };

    const websiteChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWebsite(event.target.value);
    };

    const tokenQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTokenQuantity(Number(event.target.value));
    };

    const chooseDeployerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChooseDeployer(event.target.value as MintAddressOption);
    };

    const customWalletPrivateKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomWalletPrivateKey(event.target.value);
    };

    const selectedBuyerWalletChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedBuyerWallet = [...selectedBuyerWallet];
        if (selectedBuyerWallet[index]) {
            newSelectedBuyerWallet[index] = !newSelectedBuyerWallet[index];
        } else {
            const checkedCount = selectedBuyerWallet.filter(Boolean).length;
            if (checkedCount < BUYERS_WALLET_LIMIT) {
                newSelectedBuyerWallet[index] = !newSelectedBuyerWallet[index];
            }
        }

        setSelectedBuyerWallet(newSelectedBuyerWallet);
    };

    const buyWithDelayChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBuyWithDelay(event.target.checked);
    };

    const selectWalletsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedBuyerWallet(new Array(selectWalletsNumber).fill(!selectWallets));
        setSelectWallets(event.target.checked);
    };

    const selectWalletsNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectWalletsNumber(Number(event.target.value));
    };

    useEffect(() => {
        if (role.toLowerCase() !== 'feedlistener') return;

        if (feedGetewayClient) {
            feedGetewayClient.on(REMOTE_TOKEN_DEPLOY, (params: any[]) => {
                const [appName, roomId, metadata] = params;
                console.log('remote token detected', appName, roomId, metadata);
                console.log('roomId', roomId);
                if (roomId !== sessionId) {
                    console.log(`wrong session launch detected: ${roomId} !== ${sessionId}`);
                    return;
                }
                if (appName !== 'PUMP') {
                    console.log('wrong app launch detected');
                    return;
                }

                console.log('metadata', metadata);
                logMessage('Remote token detected', 'success');

                setName(metadata.tokenName);
                setTokenTicker(metadata.tokenSymbol);
                setTokenDescription(metadata.tokenDescription);
                setTokenImage(metadata.customImageUrl);

                if (metadata.autoDeploy) {
                    //multiLaunch(undefined, metadata);
                }
            });
        }

        return () => {
            if (feedGetewayClient) {
                feedGetewayClient.off(REMOTE_TOKEN_DEPLOY);
            }
        };
    }, [role, sessionId, feedGetewayClient]);

    useEffect(() => {
        if (buyers && buyers.length > 0) {
            setSelectedBuyerWallet(new Array(buyers.length).fill(false));
        }
    }, [buyers]);

    const onCreateCoinClick = async () => {
        console.info(`Uploading metadata`);
        toast.info(`Uploading metadata`);
        logMessage(`Uploading metadata`, 'info');

        // Uploads metadata
        const uploadMetadataResponse = await PumpFunApi.uploadMetadata({
            file: tokenImage!,
            name: name,
            symbol: tokenTicker,
            description: tokenDescription,
            twitter: twitterLink,
            telegram: telegramLink,
            website: website,
            showName: 'true',
        });

        console.debug('Upload metadata response', uploadMetadataResponse);

        console.log(`Metadata uploaded`);
        toast.success(`Metadata uploaded`);
        logMessage(`Metadata uploaded`, 'success');

        // Retrieves mint
        const mint =
            chooseDeployer === MintAddressOption.Pump
                ? await PvmbApi.getVanity()
                : chooseDeployer === MintAddressOption.Random
                  ? Keypair.generate()
                  : Keypair.fromSecretKey(
                        customWalletPrivateKey.includes('[')
                            ? Uint8Array.from(JSON.parse(customWalletPrivateKey))
                            : bs58.decode(customWalletPrivateKey),
                    );

        console.debug(`Mint`, mint.publicKey.toBase58());

        // Calculating PDAs
        const bondingCurvePubkey = PumpFun.getBondingCurvePubkey(mint.publicKey);
        const associatedBondingCurvePubkey = PumpFun.getAssociatedBondingCurvePubkey(mint.publicKey, bondingCurvePubkey);
        const metadataPubkey = PumpFun.getMetadataPubkey(mint.publicKey);

        console.debug('BondingCurve', bondingCurvePubkey.toBase58());
        console.debug('AssociatedBondingCurve', associatedBondingCurvePubkey.toBase58());
        console.debug('Metadata', metadataPubkey.toBase58());

        // Retrieves selected buyers/amounts/slippages
        const selectedBuyers = buyers.filter((_, index) => selectedBuyerWallet[index]);
        const selectedBuyAmounts = buyAmounts.filter((_, index) => selectedBuyerWallet[index]);
        const selectedSlippages = slippages.filter((_, index) => selectedBuyerWallet[index]);

        const deployerAtaPubkey = getAssociatedTokenAddressSync(mint.publicKey, deployers[0].publicKey);

        console.debug('Deployer ATA', deployerAtaPubkey.toBase58());

        // ALT
        const altAccount = await new Promise<AddressLookupTableAccount>(async (resolve) => {
            console.info(`ALT bundle creating`);
            logMessage(`ALT bundle creating`, 'info');

            const latestBlockhash = await connection.getLatestBlockhash();

            const ixs = [createTipInstruction(deployers[0].publicKey, jitoTips[0] * LAMPORTS_PER_SOL)];

            const [createAltIx, altPubkey] = AddressLookupTableProgram.createLookupTable({
                authority: deployers[0].publicKey,
                payer: deployers[0].publicKey,
                recentSlot: await connection.getSlot().then((res) => res - 1),
            });

            console.debug('ALT', altPubkey.toBase58());

            ixs.push(createAltIx);

            // Extends ALT with constants public keys
            ixs.push(
                AddressLookupTableProgram.extendLookupTable({
                    lookupTable: altPubkey,
                    authority: deployers[0].publicKey,
                    payer: deployers[0].publicKey,
                    addresses: [
                        mint.publicKey,
                        PumpFun.MINT_AUTHORITY_PUBKEY,
                        bondingCurvePubkey,
                        associatedBondingCurvePubkey,
                        PumpFun.GLOBAL_PUBKEY,
                        PumpFun.MPL_TOKEN_METADATA_PUBKEY,
                        metadataPubkey,
                        deployers[0].publicKey,
                        deployerAtaPubkey,
                        SystemProgram.programId,
                        TOKEN_PROGRAM_ID,
                        ASSOCIATED_TOKEN_PROGRAM_ID,
                        PumpFun.EVENT_AUTHORITY_PUBKEY,
                        PumpFun.PROGRAM_PUBKEY,
                        PumpFun.FEE_RECIPIENT_PUBKEY,
                        SYSVAR_RENT_PUBKEY,
                    ],
                }),
            );

            if (selectedBuyers.length > 0) {
                // Extends ALT with selected buyers public keys
                ixs.push(
                    AddressLookupTableProgram.extendLookupTable({
                        lookupTable: altPubkey,
                        authority: deployers[0].publicKey,
                        payer: deployers[0].publicKey,
                        addresses: selectedBuyers.map((buyer) => buyer.publicKey),
                    }),
                );

                // Extends ALT with selected buyers ATA public keys
                ixs.push(
                    AddressLookupTableProgram.extendLookupTable({
                        lookupTable: altPubkey,
                        authority: deployers[0].publicKey,
                        payer: deployers[0].publicKey,
                        addresses: selectedBuyers.map((buyer) => getAssociatedTokenAddressSync(mint.publicKey, buyer.publicKey)),
                    }),
                );
            }

            const bundle = new Bundle(
                ...ixs.map((ix) => {
                    const tx = new VersionedTransaction(
                        new TransactionMessage({
                            payerKey: deployers[0].publicKey,
                            recentBlockhash: latestBlockhash.blockhash,
                            instructions: [ix],
                        }).compileToV0Message(),
                    );

                    tx.sign([deployers[0]]);

                    return tx;
                }),
            );

            const bundleSignatures = bundle.signatures();

            console.debug('ALT bundle signatures', bundleSignatures);

            {
                const simulation = await connection.simulateTransaction(bundle[0]);

                console.debug('ALT bundle tip tx simulation', simulation);

                if (simulation.value.err) {
                    console.error('ALT bundle tip tx simulation error', simulation.value.err);
                    toast.error('ALT bundle tip tx simulation error');
                    logMessage(`ALT bundle tip tx simulation error`, 'error');
                    throw new Error('ALT bundle tip tx simulation error');
                }
            }

            {
                const simulation = await connection.simulateTransaction(bundle[1]);

                console.debug('ALT bundle create ALT tx simulation', simulation);

                if (simulation.value.err) {
                    console.error('ALT bundle create ALT tx simulation error', simulation.value.err);
                    toast.error('ALT bundle create ALT tx simulation error');
                    logMessage(`ALT bundle create ALT tx simulation error`, 'error');
                    throw new Error('ALT bundle create ALT tx simulation error');
                }
            }

            const bundleId = await sendBundle(connection, bundle);

            console.info(`ALT bundle sent`, bundleId);
            toast.info(`ALT bundle sent`);
            logMessage(`ALT bundle sent`, 'info', 'jito', bundleId);

            await Promise.all(
                bundleSignatures.map((signature, index) =>
                    connection
                        .confirmTransaction(
                            {
                                signature,
                                ...latestBlockhash,
                            },
                            'confirmed',
                        )
                        .then(() => {
                            console.log(`ALT bundle tx confirmed`, index + 1, signature);
                            logMessage(`ALT bundle tx(${index + 1}/${bundle.length}) confirmed`, 'success', 'solscan', signature);
                        }),
                ),
            );

            console.log(`ALT bundle confirmed`, bundleId);
            toast.success(`ALT bundle confirmed`);
            logMessage(`ALT bundle confirmed`, 'success', 'jito', bundleId);

            const getAltAccount = (): Promise<AddressLookupTableAccount> =>
                connection
                    .getAddressLookupTable(altPubkey, {
                        commitment: 'confirmed',
                    })
                    .then((res) => (res.value ? res.value : getAltAccount()))
                    .catch(getAltAccount);

            getAltAccount().then(() => getAltAccount().then(resolve));
        });

        console.debug('ALT account', altAccount);

        let currentBondingCurveMetadata = PumpFun.DEFAULT_BONDING_CURVE_METADATA;

        const getBuyIx = async (keypair: Keypair, ataPubkey: PublicKey, lamports: BN, slippage: number) => {
            let amount = getExchangeRate(lamports, currentBondingCurveMetadata);
            let maxSolCost =
                slippage === 100
                    ? new BN(LAMPORTS_PER_SOL).mul(new BN(100))
                    : lamports.mul(new BN(100 + slippage)).div(new BN(100));

            const provider = new AnchorProvider(connection, new MyWallet(keypair), AnchorProvider.defaultOptions());
            const program = new Program(pumpProgramIdl, PumpFun.PROGRAM_PUBKEY, provider);

            const ix = await program.methods
                .buy(amount, maxSolCost)
                .accounts({
                    global: PumpFun.GLOBAL_PUBKEY,
                    feeRecipient: PumpFun.FEE_RECIPIENT_PUBKEY,
                    mint: mint.publicKey,
                    bondingCurve: bondingCurvePubkey,
                    associatedBondingCurve: associatedBondingCurvePubkey,
                    associatedUser: ataPubkey,
                    user: keypair.publicKey,
                    systemProgram: SystemProgram.programId,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: SYSVAR_RENT_PUBKEY,
                    eventAuthority: PumpFun.EVENT_AUTHORITY_PUBKEY,
                    program: PumpFun.PROGRAM_PUBKEY,
                })
                .instruction();

            currentBondingCurveMetadata.virtualTokenReserves = currentBondingCurveMetadata.virtualTokenReserves.sub(amount);
            currentBondingCurveMetadata.virtualSolReserves = currentBondingCurveMetadata.virtualSolReserves.add(lamports);
            currentBondingCurveMetadata.realTokenReserves = currentBondingCurveMetadata.realTokenReserves.sub(amount);
            currentBondingCurveMetadata.realSolReserves = currentBondingCurveMetadata.realSolReserves.add(lamports);

            return ix;
        };

        // Create token
        await new Promise<void>(async (resolve) => {
            console.info(`Create token bundle creating`);
            logMessage(`Create token bundle creating`, 'info');

            const bundleFactory = new CreateAndBuyBundleFactory(
                [
                    createTipInstruction(deployers[0].publicKey, jitoTips[0] * LAMPORTS_PER_SOL),
                    await new Promise<TransactionInstruction>(async (resolve) => {
                        const provider = new AnchorProvider(
                            connection,
                            new MyWallet(deployers[0]),
                            AnchorProvider.defaultOptions(),
                        );
                        const program = new Program(pumpProgramIdl, PumpFun.PROGRAM_PUBKEY, provider);

                        resolve(
                            program.methods
                                .create(name, tokenTicker, uploadMetadataResponse.metadataUri)
                                .accounts({
                                    mint: mint.publicKey,
                                    mintAuthority: PumpFun.MINT_AUTHORITY_PUBKEY,
                                    bondingCurve: bondingCurvePubkey,
                                    associatedBondingCurve: associatedBondingCurvePubkey,
                                    global: PumpFun.GLOBAL_PUBKEY,
                                    mplTokenMetadata: PumpFun.MPL_TOKEN_METADATA_PUBKEY,
                                    metadata: metadataPubkey,
                                    user: deployers[0].publicKey,
                                    systemProgram: SystemProgram.programId,
                                    tokenProgram: TOKEN_PROGRAM_ID,
                                    associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                                    rent: SYSVAR_RENT_PUBKEY,
                                    eventAuthority: PumpFun.EVENT_AUTHORITY_PUBKEY,
                                    program: PumpFun.PROGRAM_PUBKEY,
                                })
                                .instruction(),
                        );
                    }),
                ],
                [deployers[0], mint],
                altAccount,
            );

            if (tokenQuantity > 0) {
                bundleFactory.addToCreate(
                    [
                        createAssociatedTokenAccountInstruction(
                            deployers[0].publicKey,
                            deployerAtaPubkey,
                            deployers[0].publicKey,
                            mint.publicKey,
                        ),
                        await getBuyIx(deployers[0], deployerAtaPubkey, new BN(tokenQuantity * LAMPORTS_PER_SOL), slippages[0]),
                    ],
                    [],
                );
            }

            for (let index = 0; index < selectedBuyers.length; index++) {
                const ataPubkey = getAssociatedTokenAddressSync(mint.publicKey, selectedBuyers[index].publicKey);
                bundleFactory.addBuy(
                    await getBuyIx(
                        selectedBuyers[index],
                        ataPubkey,
                        new BN(selectedBuyAmounts[index] * LAMPORTS_PER_SOL),
                        selectedSlippages[index],
                    ),
                    selectedBuyers[index],
                    createAssociatedTokenAccountInstruction(
                        selectedBuyers[index].publicKey,
                        ataPubkey,
                        selectedBuyers[index].publicKey,
                        mint.publicKey,
                    ),
                );
            }

            const latestBlockhash = await connection.getLatestBlockhash();

            const bundle = bundleFactory.build(latestBlockhash.blockhash);

            const bundleSignatures = bundle.signatures();

            console.debug('Create token bundle signatures', bundleSignatures);

            {
                const simulation = await connection.simulateTransaction(bundle[0], {
                    commitment: 'processed',
                });

                console.debug('Create token tx simulation', simulation);

                if (simulation.value.err) {
                    console.error('Create token tx simulation error', simulation.value.err);
                    toast.error('Create token tx simulation error');
                    logMessage(`Create token tx simulation error`, 'error');
                    throw new Error('Create token tx simulation error');
                }
            }

            const bundleId = await sendBundle(connection, bundle);

            console.info(`Create token bundle sent`, bundleId);
            toast.info(`Create token bundle sent`);
            logMessage(`Create token bundle sent`, 'info', 'jito', bundleId);

            Promise.all(
                bundleSignatures.map((signature, index) =>
                    connection
                        .confirmTransaction(
                            {
                                signature,
                                ...latestBlockhash,
                            },
                            'confirmed',
                        )
                        .then(() => {
                            console.log(`Create token bundle tx confirmed`, index + 1, signature);
                            logMessage(
                                `Create token bundle tx(${index + 1}/${bundle.length}) confirmed`,
                                'success',
                                'solscan',
                                signature,
                            );
                        }),
                ),
            ).then(() => {
                console.log(`Create token bundle confirmed`, bundleId);
                toast.success('Create token bundle confirmed');
                logMessage(`Create token bundle confirmed`, 'success', 'jito', bundleId);
                setTokenStatus(TokenStatus.Pump);
                setTokenMint(mint.publicKey.toBase58());
                setTokenName(name);
                setTokenSymbol(tokenTicker);
                setTokenLoaded(true);
                setBondingCurve(bondingCurvePubkey.toBase58());
                setAssociatedBondingCurve(associatedBondingCurvePubkey.toBase58());
            });

            resolve();
        });

        // Delayed buys
        await new Promise<void>(async (resolve) => {
            if (!buyWithDelay) return;

            const unselectedBuyers = buyers.filter((_, index) => index > 0 && !selectedBuyerWallet[index] && delays[index] >= 0);
            const unselectedBuyAmounts = buyAmounts.filter(
                (_, index) => index > 0 && !selectedBuyerWallet[index] && delays[index] >= 0,
            );
            const unselectedSlippages = slippages.filter(
                (_, index) => index > 0 && !selectedBuyerWallet[index] && delays[index] >= 0,
            );
            const unselectedDelays = delays.filter((_, index) => index > 0 && !selectedBuyerWallet[index] && delays[index] >= 0);
            const unselectedTips = jitoTips.filter((_, index) => index > 0 && !selectedBuyerWallet[index] && delays[index] >= 0);

            const semaphore = new Semaphore(1);

            unselectedDelays.forEach((delay, i) => {
                setTimeout(async () => {
                    console.debug(`Delayed buy`, i, delay);
                    const ataPubkey = getAssociatedTokenAddressSync(mint.publicKey, unselectedBuyers[i].publicKey);

                    const priorFee = 2000000;

                    const ixs = [
                        ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: priorFee,
                        }),
                        createAssociatedTokenAccountInstruction(
                            unselectedBuyers[i].publicKey,
                            ataPubkey,
                            unselectedBuyers[i].publicKey,
                            mint.publicKey,
                        ),
                        await semaphore.runExclusive(
                            () =>
                                getBuyIx(
                                    unselectedBuyers[i],
                                    ataPubkey,
                                    new BN(unselectedBuyAmounts[i] * LAMPORTS_PER_SOL),
                                    unselectedSlippages[i],
                                ),
                            undefined,
                            i,
                        ),
                    ];

                    const latestBlockhash = await connection.getLatestBlockhash();

                    const tx = new VersionedTransaction(
                        new TransactionMessage({
                            payerKey: unselectedBuyers[i].publicKey,
                            recentBlockhash: latestBlockhash.blockhash,
                            instructions: ixs,
                        }).compileToV0Message(),
                    );

                    tx.sign([unselectedBuyers[i]]);

                    const signature = bs58.encode(tx.signatures[0]);

                    console.debug(`Delayed buy signature`, i + 1, signature);

                    console.info(`Spamming delayed buy`, i + 1);
                    toast.info(`Spamming delayed buy(${i + 1}/${unselectedBuyers.length})`);
                    logMessage(`Spamming delayed buy(${i + 1}/${unselectedBuyers.length})`, 'info', 'solscan', signature);

                    await new Promise<void>((resolve) => {
                        const interval = setInterval(async () => {
                            try {
                                await connection.sendTransaction(tx, {
                                    skipPreflight: true,
                                });
                            } catch {}
                        }, 100);
                        const signatureId = connection.onSignatureWithOptions(
                            signature,
                            (notification: SignatureStatusNotification | SignatureReceivedNotification, context: Context) => {
                                if (notification.type === 'status') {
                                    if (notification.result.err) {
                                        console.log(`Delayed buy error`, i + 1, notification.result.err);
                                        toast.error(`Delayed buy(${i + 1}/${unselectedBuyers.length}) error`);
                                        logMessage(`Delayed buy(${i + 1}/${unselectedBuyers.length}) error`, 'error');
                                        return;
                                    }
                                }
                                console.log(`Delayed buy confirmed`, i + 1, signature);
                                toast.success(`Delayed buy(${i + 1}/${unselectedBuyers.length}) confirmed`);
                                logMessage(
                                    `Delayed buy(${i + 1}/${unselectedBuyers.length}) confirmed`,
                                    'success',
                                    'solscan',
                                    signature,
                                );
                                clearInterval(interval);
                                connection.removeSignatureListener(signatureId);
                                resolve();
                            },
                            {
                                commitment: 'confirmed',
                            },
                        );
                    });
                }, delay);
            });

            resolve();
        });
    };

    return (
        <section className={TokenCreatorCSS.container}>
            <h2 className={TokenCreatorCSS.header}>Start a New Coin</h2>
            <div className={TokenCreatorCSS.tokenCreateWrap}>
                <fieldset className={TokenCreatorCSS.mainOptions}>
                    <div>
                        <label htmlFor='name'>Name</label>
                        <input type='text' id='name' value={name} onChange={tokenNameChange} />
                    </div>

                    <div>
                        <label htmlFor='ticker'>Ticker</label>
                        <input type='text' id='ticker' value={tokenTicker} onChange={tokenTickerChange} />
                    </div>

                    <div>
                        <label htmlFor='description'>Description</label>
                        <textarea id='description' value={tokenDescription} onChange={tokenDescriptionChange} />
                    </div>

                    <div>
                        <b>Image</b>
                        <input type='file' accept='image/*' id='image' onChange={tokenImageChange} />
                        <label className='imageInput' htmlFor='image'>
                            <span className={tokenImage ? TokenCreatorCSS.imageName : TokenCreatorCSS.imagePlaceholder}>
                                Choose File
                            </span>
                        </label>
                    </div>

                    <div>
                        <label htmlFor='twitter'>Twitter Link</label>
                        <input
                            type='text'
                            placeholder='(optional)'
                            id='twitter'
                            value={twitterLink}
                            onChange={twitterLinkChange}
                        />
                    </div>

                    <div>
                        <label htmlFor='telegram'>Telegram Link</label>
                        <input
                            type='text'
                            placeholder='(optional)'
                            id='telegram'
                            value={telegramLink}
                            onChange={telegramLinkChange}
                        />
                    </div>

                    <div>
                        <label htmlFor='website'>Website</label>
                        <input type='text' placeholder='(optional)' id='website' value={website} onChange={websiteChange} />
                    </div>
                </fieldset>

                <fieldset className={TokenCreatorCSS.additionalOptions}>
                    <div className={TokenCreatorCSS.chooseQuantity}>
                        <label htmlFor='quantity'>Choose how many {name ? name : 'tokens'} you want to buy</label>

                        <div className={TokenCreatorCSS.tooltipWrap}>
                            <span className={TokenCreatorCSS.tooltipIcon}></span>
                            <p className={TokenCreatorCSS.tooltipText}>
                                It's optional, but buying a small amount of coins helps protect your coin from snipers
                            </p>

                            {/*<span className={TokenCreatorCSS.toggle} onClick={() => setToggleBuy(!toggleBuy)}>*/}
                            {/*    {toggleBuy ? 'switch to SOL' : `switch to ${tokenTicker ? tokenTicker : 'token'}`}*/}
                            {/*</span>*/}
                        </div>

                        <div className={TokenCreatorCSS.tokenQuantityInput}>
                            <input
                                type='number'
                                placeholder='(optional)'
                                id='quantity'
                                value={tokenQuantity}
                                onChange={tokenQuantityChange}
                            />
                            {/*{toggleBuy ? (*/}
                            {/*    <div className={TokenCreatorCSS.chooseQuantityWrap}>*/}
                            {/*        <p>{tokenTicker}</p>*/}
                            {/*        {tokenImagePreview ? (*/}
                            {/*            <img className={TokenCreatorCSS.image} src={tokenImagePreview} alt='Token Logo' />*/}
                            {/*        ) : (*/}
                            {/*            <img*/}
                            {/*                className={TokenCreatorCSS.image}*/}
                            {/*                src='https://placehold.co/30x30/000000/FFF'*/}
                            {/*                alt='Placeholder Logo'*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            <div className={TokenCreatorCSS.chooseQuantityWrap}>
                                <p>SOL</p>
                                <img className={TokenCreatorCSS.image} src={solLogo} alt='SOL Logo' />
                            </div>
                            {/*)}*/}
                        </div>
                        {/*<p className={TokenCreatorCSS.receive}>*/}
                        {/*    You receive: {} {tokenName}*/}
                        {/*</p>*/}
                    </div>

                    <div className={TokenCreatorCSS.choosePattern}>
                        <b>Choose Token Address Pattern</b>

                        <div className={TokenCreatorCSS.choosePatternOption}>
                            <input
                                type='radio'
                                name='pattern'
                                id='pump'
                                value={MintAddressOption.Pump}
                                checked={chooseDeployer === MintAddressOption.Pump}
                                onChange={chooseDeployerChange}
                            />
                            <label htmlFor='pump'>"pump" Suffix</label>
                        </div>

                        <div className={TokenCreatorCSS.choosePatternOption}>
                            <input
                                type='radio'
                                name='pattern'
                                id='custom'
                                value={MintAddressOption.Custom}
                                checked={chooseDeployer === MintAddressOption.Custom}
                                onChange={chooseDeployerChange}
                            />
                            <label htmlFor='custom'>Custom Wallet</label>
                        </div>

                        {chooseDeployer === MintAddressOption.Custom && (
                            <div className={TokenCreatorCSS.privateKeyInput}>
                                <label htmlFor='privateKey'>Private Key</label>
                                <input
                                    type='text'
                                    id='privateKey'
                                    value={customWalletPrivateKey}
                                    onChange={customWalletPrivateKeyChange}
                                />
                            </div>
                        )}

                        <div className={TokenCreatorCSS.choosePatternOption}>
                            <input
                                type='radio'
                                name='pattern'
                                id='random'
                                value={MintAddressOption.Random}
                                checked={chooseDeployer === MintAddressOption.Random}
                                onChange={chooseDeployerChange}
                            />
                            <label htmlFor='random'>Random Address</label>
                        </div>
                    </div>

                    <div className={TokenCreatorCSS.footer}>
                        <button className='buttonAccentGreen' onClick={onCreateCoinClick}>
                            Create Coin
                        </button>
                        <p>Cost to deploy: ~0.02 SOL</p>
                    </div>
                </fieldset>

                <fieldset className={TokenCreatorCSS.buyerWalletOptions}>
                    <div className={TokenCreatorCSS.buyerWalletWrap}>
                        <div className={TokenCreatorCSS.selectWallets}>
                            <input
                                type='checkbox'
                                checked={selectWallets}
                                onChange={selectWalletsChange}
                                disabled={!buyers.length}
                            />
                            <b>Select First</b>
                            <input
                                className={TokenCreatorCSS.selectWalletsInput}
                                type='number'
                                value={selectWalletsNumber}
                                onChange={selectWalletsNumberChange}
                                disabled={!buyers.length}
                            />
                            <b>Wallets</b>
                        </div>
                        <b>Choose Buyer Wallets to bundle together (max {BUYERS_WALLET_LIMIT})</b>
                        <div className={TokenCreatorCSS.buyerWalletOptionScrollbar}>
                            {buyers.map((wallet, index) => (
                                <div key={index} className={TokenCreatorCSS.buyerWalletOption}>
                                    <input
                                        type='checkbox'
                                        id={`buyer${index}`}
                                        checked={selectedBuyerWallet[index] || false}
                                        onChange={selectedBuyerWalletChange(index)}
                                    />
                                    <label htmlFor={`buyer${index}`}>{sliceWalletAddress(wallet.publicKey.toBase58(), 7)}</label>
                                    <b>| {countedRoles[index]}</b>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={TokenCreatorCSS.buyWithDelayWrap}>
                        <input type='checkbox' id='delayBuy' checked={buyWithDelay} onChange={buyWithDelayChange} />
                        <label htmlFor='delayBuy'>Buy rest of wallets according to delay MS</label>
                    </div>
                </fieldset>
            </div>
        </section>
    );
}
