import axios, { AxiosInstance } from 'axios';
import { Keypair } from '@solana/web3.js';

enum Endpoints {
    Vanity = 'pump/vanity',
}

type GetVanityResponse = Array<number>;

class PvmbApi {
    private readonly axios: AxiosInstance;

    constructor(apiKey: string) {
        this.axios = axios.create({
            headers: {
                'x-api-key': apiKey,
            },
        });
        this.axios.interceptors.response.use((res) => res.data);
    }

    public getVanity() {
        return this.axios
            .get<GetVanityResponse, GetVanityResponse>(`${process.env.REACT_APP_PVMB_API_URL}/${Endpoints.Vanity}`)
            .then((res) => Keypair.fromSecretKey(Uint8Array.from(res)));
    }
}

if (!process.env.REACT_APP_PVMB_API_KEY) throw new Error('REACT_APP_PVMB_API_KEY not provided');

// eslint-disable-next-line import/no-anonymous-default-export
export default new PvmbApi(process.env.REACT_APP_PVMB_API_KEY!);
