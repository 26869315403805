import { Keypair, PublicKey } from '@solana/web3.js';

function removeKeypairDuplicates(keypairs: Keypair[]): Keypair[] {
    const seen: Set<string> = new Set();
    return keypairs.filter((keypair) => {
        const keyString: string = JSON.stringify(keypair);
        const duplicate: boolean = seen.has(keyString);
        seen.add(keyString);
        return !duplicate;
    });
}

function removePublicKeys(publicKeys: PublicKey[]): PublicKey[] {
    const seen: Set<string> = new Set();
    return publicKeys.filter((publicKey) => {
        const keyString: string = publicKey.toBase58();
        const duplicate: boolean = seen.has(keyString);
        seen.add(keyString);
        return !duplicate;
    });
}

export { removeKeypairDuplicates, removePublicKeys };
