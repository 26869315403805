import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    ComputeBudgetProgram,
    Connection,
    Keypair,
    LAMPORTS_PER_SOL,
    PublicKey,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import { useSolana } from '../hooks/useSolana';
import { useClipboard } from '../hooks/useClipboars';
import { usePump } from '../hooks/usePump';
import { useJito } from '../hooks/useJito';
import MyWallet from '../utils/anchor-wallet';
import BuySellCSS from '../styles/BuySell.module.css';
import { useSplToken } from '../hooks/useSplToken';
import { useMetaplex } from '../hooks/useMetaplex';
import { AnchorProvider, BN, Program } from '@coral-xyz/anchor';
import { coingeckoApiSolUrl, MPL_TOKEN_METADATA, PUMP_DEX_CURVE_PERCENT, PUMP_FUN_PROGRAM_ID } from '../utils/constants';
import pumpProgramIdl from '../utils/pump-program-idl';
import axios from 'axios';
import { WSOL } from '@raydium-io/raydium-sdk';
import { useRaydium } from '../hooks/useRaydium';
import { removeKeypairDuplicates } from '../utils/remove-keypair-duplicates';
import { TokenStatus } from '../../App';
import { sliceWalletAddress } from '../utils/role-formatting';

export function BuySell({
    connection,
    defaultSellPercent,
    wallets,
    setWallets,
    walletPublicKeys,
    setWalletPublicKeys,
    solBuys,
    setSolBuys,
    delays,
    setDelays,
    slippages,
    setSlippages,
    jitoTips,
    setJitoTips,
    solValues,
    setSolValues,
    tokenMint,
    setTokenMint,
    tokenLoaded,
    setTokenLoaded,
    setTokenName,
    setTokenSymbol,
    bondingCurve,
    setBondingCurve,
    associatedBondingCurve,
    setAssociatedBondingCurve,
    tokenBalances,
    setTokenBalances,
    deployers,
    setDeployers,
    setMix,
    jitoEnabled,
    jitoPriorityFee,
    rpcTxEnabled,
    rpcTxPriorityFee,
    logMessage,
    allWalletRoles,
    setAllWalletRoles,
    totalInitBalance,
    tokenStatus,
    setTokenStatus,
    ammPool,
    setAmmPool,
}: {
    connection: Connection;
    defaultSellPercent: number;
    wallets: Keypair[];
    setWallets: Dispatch<SetStateAction<Keypair[]>>;
    walletPublicKeys: string[];
    setWalletPublicKeys: Dispatch<SetStateAction<string[]>>;
    solBuys: number[];
    setSolBuys: Dispatch<SetStateAction<number[]>>;
    delays: number[];
    setDelays: Dispatch<SetStateAction<number[]>>;
    slippages: number[];
    setSlippages: Dispatch<SetStateAction<number[]>>;
    jitoTips: number[];
    setJitoTips: Dispatch<SetStateAction<number[]>>;
    solValues: number[];
    setSolValues: Dispatch<SetStateAction<number[]>>;
    tokenMint: string;
    setTokenMint: Dispatch<SetStateAction<string>>;
    tokenLoaded: boolean;
    setTokenLoaded: Dispatch<SetStateAction<boolean>>;
    setTokenName: Dispatch<SetStateAction<string>>;
    setTokenSymbol: Dispatch<SetStateAction<string>>;
    bondingCurve: string | undefined;
    setBondingCurve: Dispatch<SetStateAction<string | undefined>>;
    associatedBondingCurve: string | undefined;
    setAssociatedBondingCurve: Dispatch<SetStateAction<string | undefined>>;
    tokenBalances: number[];
    setTokenBalances: Dispatch<SetStateAction<number[]>>;
    deployers: Keypair[];
    setDeployers: Dispatch<SetStateAction<Keypair[]>>;
    setMix: Dispatch<SetStateAction<Keypair[]>>;
    jitoEnabled: boolean;
    jitoPriorityFee: number;
    rpcTxEnabled: boolean;
    rpcTxPriorityFee: number;
    logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void;
    allWalletRoles: string[];
    setAllWalletRoles: Dispatch<SetStateAction<string[]>>;
    totalInitBalance: number;
    tokenStatus: string;
    setTokenStatus: React.Dispatch<React.SetStateAction<TokenStatus>>;
    ammPool: string | undefined;
    setAmmPool: Dispatch<SetStateAction<string | undefined>>;
}) {
    const { isCopied: isTokenMintCopied, copyToClipboard: copyTokenMint } = useClipboard();
    const { isCopied: isWalletToScanCopied, copyToClipboard: copyWalletToScan } = useClipboard();
    const { copyToClipboard } = useClipboard();
    const { getMultipleSolBalances } = useSolana();
    const { getNameAndSymbol } = useMetaplex();
    const { getDetailsFromTokenMint, getBuyInstructions, getSellInstruction } = usePump();
    const { sendBundle, getTipInstructions } = useJito();
    const { getWalletsTokenStringBalances, findAssociatedTokenAddress, getCreateAssociatedTokenAccountInstruction } =
        useSplToken();
    const { fetchMarketAccountsByMints, getSwapInstructions } = useRaydium();
    const availableModes = ['Manual Buy', 'Snipe Wallet Creation', 'Snipe Deployer'];
    const [mode, setMode] = useState<string>(availableModes[0]);

    const [balances, setBalances] = useState<number[]>(new Array(wallets.length).fill(0));
    const [totalSolBalance, setTotalSolBalance] = useState<number>(0);
    const totalSolBuy = Number(solBuys.reduce((acc, solBuy) => acc + solBuy, 0).toFixed(5));
    const [totalTokenBalance, setTotalTokenBalance] = useState<number>(0);
    const [supplyOwnPercent, setSupplyOwnPercent] = useState<number>(0);
    const [totalSolValues, setTotalSolValues] = useState<number>(0);
    const [walletSelectedBuy, setWalletSelectedBuy] = useState<boolean[]>(new Array(wallets.length).fill(false));
    const [selectAllBuy, setSelectAllBuy] = useState<boolean>(false);
    const [walletSelectedSell, setWalletSelectedSell] = useState<boolean[]>(new Array(wallets.length).fill(false));
    const [selectAllSell, setSelectAllSell] = useState<boolean>(false);
    const [customSellValue, setCustomSellValue] = useState<number[]>([]);
    const [customSellValueAll, setCustomSellValueAll] = useState<number>(defaultSellPercent);
    const [raydiumSellPercent, setRaydiumSellPercent] = useState<number[]>(new Array(wallets.length).fill(100));
    const [snipeSellOnRaydiumStarted, setSnipeSellOnRaydiumStarted] = useState<boolean>(false);

    const [walletToScan, setWalletToScan] = useState<string>('');
    const [scanStarted, setScanStarted] = useState<boolean>(false);

    const [completeCurvePercent, setCompleteCurvePercent] = useState<string>('0');
    const [completeCurveDollar, setCompleteCurveDollar] = useState<string>('0');

    const [solPrice, setSolPrice] = useState<number>(0);

    //TODO: calculate for each transaction
    const lamportsToSolTipForSwapRPC = 1e10 / 2;
    const lamportsToSolTipForSwapJito = 1e10 / 4;

    const modeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === availableModes[1]) {
            setTokenMint('');
            setTokenLoaded(false);
            setTokenStatus(TokenStatus.NotLoaded);
        }
        setMode(event.target.value);
    };

    const solBuysChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSolBuys = [...solBuys];
        newSolBuys[index] = Number(event.target.value);
        setSolBuys(newSolBuys);
    };

    const delaysChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDelays = [...delays];
        newDelays[index] = Number(event.target.value);
        setDelays(newDelays);
    };

    const slippagesChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSlippages = [...slippages];
        newSlippages[index] = Number(event.target.value);
        setSlippages(newSlippages);
    };

    const jitoTipsChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newJitoTips = [...jitoTips];
        newJitoTips[index] = Number(event.target.value);
        setJitoTips(newJitoTips);
    };

    const walletSelectedBuyChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newWalletSelectedBuy = [...walletSelectedBuy];
        newWalletSelectedBuy[index] = event.target.checked;
        setWalletSelectedBuy(newWalletSelectedBuy);
    };

    const walletSelectedSellChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newWalletSelectedSell = [...walletSelectedSell];
        newWalletSelectedSell[index] = event.target.checked;
        setWalletSelectedSell(newWalletSelectedSell);
    };

    const customSellValueChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCustomSellValue = [...customSellValue];
        newCustomSellValue[index] = Number(event.target.value);
        setCustomSellValue(newCustomSellValue);
    };

    const customSellValueAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomSellValueAll(Number(event.target.value));
    };

    const raydiumSellPercentChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRaydiumSellPercent = [...raydiumSellPercent];
        newRaydiumSellPercent[index] = Number(event.target.value);
        setRaydiumSellPercent(newRaydiumSellPercent);
    };

    const tokenMintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenMint(event.target.value);
    };

    const selectAllBuyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletSelectedBuy(new Array(wallets.length).fill(!selectAllBuy));
        setSelectAllBuy(event.target.checked);
    };

    const selectAllSellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletSelectedSell(new Array(wallets.length).fill(!selectAllSell));
        setSelectAllSell(event.target.checked);
    };

    const walletToScanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletToScan(event.target.value);
    };

    useEffect(() => {
        const fetchBalances = async () => {
            const allSolBalances = await getMultipleSolBalances(
                connection,
                wallets.map((keypair) => keypair.publicKey),
            );
            setBalances(allSolBalances);

            const uniqueWallets = removeKeypairDuplicates(wallets);
            const uniqWalletsBalances = await getMultipleSolBalances(
                connection,
                uniqueWallets.map((keypair) => keypair.publicKey),
            );
            const totalSolBalance = Number(uniqWalletsBalances.reduce((acc, balance) => acc + balance, 0) / LAMPORTS_PER_SOL);
            setTotalSolBalance(totalSolBalance);
        };

        fetchBalances();

        const intervalId = setInterval(fetchBalances, 2000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallets]);

    useEffect(() => {
        const fetchSolPrice = async () => {
            try {
                const response = await axios.get(coingeckoApiSolUrl);
                setSolPrice(response.data.solana.usd);
            } catch (error) {
                console.log(`Error getting sol price: ${error}`);
            }
        };

        fetchSolPrice();

        const intervalId = setInterval(fetchSolPrice, 90000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                if (tokenMint === '' || wallets.length === 0) return setTokenBalances(new Array(wallets.length).fill(0));
                const allTokenBalances = await getWalletsTokenStringBalances(
                    connection,
                    wallets.map((keypair) => keypair.publicKey),
                    new PublicKey(tokenMint),
                );

                setTokenBalances(allTokenBalances.map((balance) => Number(balance)));

                const totalTokenBalance = Number(allTokenBalances.reduce((acc, balance) => acc + Number(balance), 0).toFixed(2));
                setTotalTokenBalance(totalTokenBalance);
            } catch (error) {
                console.log(`Error getting token balances: ${error}`);
            }
        }, 2000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallets, tokenMint]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (bondingCurve && wallets.length > 0 && !ammPool) {
                try {
                    const provider = new AnchorProvider(connection, new MyWallet(wallets[0]), AnchorProvider.defaultOptions());
                    const program = new Program(pumpProgramIdl, PUMP_FUN_PROGRAM_ID, provider);
                    const liquidityPool = await program.account.bondingCurve.fetch(bondingCurve, 'processed');

                    console.log('liquidityPool', liquidityPool);
                    console.log('liquidityPool real sol', liquidityPool.realSolReserves.toString());
                    console.log('liquidityPool real token', liquidityPool.realTokenReserves.toString());
                    console.log('liquidityPool virtual sol', liquidityPool.virtualSolReserves.toString());
                    console.log('liquidityPool virtual token', liquidityPool.virtualTokenReserves.toString());
                    console.log('liquidityPool token total supply', liquidityPool.tokenTotalSupply.toString());

                    const totalForSale = (liquidityPool.tokenTotalSupply * (100 - PUMP_DEX_CURVE_PERCENT)) / 100;
                    console.log('totalForSale', totalForSale);
                    const curvePercent = (totalForSale - liquidityPool.realTokenReserves) / totalForSale;
                    setCompleteCurvePercent((curvePercent * 100).toFixed(2));

                    const totalOwnPercent = (totalTokenBalance * 100) / liquidityPool.tokenTotalSupply;
                    setSupplyOwnPercent(totalOwnPercent);

                    const price = liquidityPool.virtualSolReserves / liquidityPool.virtualTokenReserves;
                    const curvePrice = (liquidityPool.tokenTotalSupply * price * solPrice) / LAMPORTS_PER_SOL;
                    setCompleteCurveDollar(curvePrice.toFixed(2));
                    //setCompleteCurvePercent(((curvePrice * 100) / PUMP_CURVE_CAP_USD).toFixed(2));

                    if (!liquidityPool.complete) {
                        setTokenStatus(TokenStatus.Pump);
                        const solValues = [];
                        for (const amount of tokenBalances) {
                            const solAmount = new BN(amount)
                                .mul(liquidityPool.virtualSolReserves)
                                .div(new BN(liquidityPool.virtualTokenReserves));
                            solValues.push(solAmount.toNumber() / LAMPORTS_PER_SOL);
                        }
                        setSolValues(solValues);

                        const totalSolValues = Number(solValues.reduce((acc, balance) => acc + Number(balance), 0).toFixed(4));
                        setTotalSolValues(totalSolValues);
                    } else {
                        setTokenStatus(TokenStatus.CurveCompleted);
                    }
                } catch (error) {
                    console.log(`Error getting sol values: ${error}`);
                    setSolValues(new Array(wallets.length).fill(0));
                }
            }
        }, 2000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallets, tokenBalances, tokenStatus, bondingCurve, ammPool]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (tokenStatus === 'Curve Completed') {
                try {
                    const ammPool = (
                        await fetchMarketAccountsByMints(connection, new PublicKey(WSOL.mint), new PublicKey(tokenMint))
                    )[0].id;
                    logMessage(`AMM Pool detected: ${ammPool}`, 'info');
                    console.log('ammPool', ammPool);
                    setAmmPool(ammPool);
                    setTokenStatus(TokenStatus.Raydium);
                    if (snipeSellOnRaydiumStarted) {
                        //filter wallets with 0 balance
                        sellMultiple(
                            connection,
                            wallets.filter((_, index) => tokenBalances[index] > 0).map((_, index) => index),
                            tokenMint,
                            raydiumSellPercent.filter((_, index) => tokenBalances[index] > 0),
                            undefined,
                            undefined,
                            ammPool,
                        );
                    }
                } catch (error) {
                    console.log(`Pool not found: ${error}`);
                }
            }
        }, 2000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenStatus, snipeSellOnRaydiumStarted]);

    async function loadToken(connection: Connection, mint: string) {
        if (mint !== '') {
            try {
                console.log('tokenMint', mint);
                const data = await getNameAndSymbol(connection, mint);
                if (data) {
                    const [name, symbol] = data;
                    const { bondingCurve, associatedBondingCurve } = getDetailsFromTokenMint(connection, mint);
                    console.log('bondingCurve', bondingCurve);

                    const ammPool = (
                        await fetchMarketAccountsByMints(connection, new PublicKey(WSOL.mint), new PublicKey(tokenMint))
                    )[0];
                    console.log('ammPool', ammPool);
                    if (ammPool) {
                        logMessage(`AMM Pool detected: ${ammPool}`, 'info');
                        console.log('ammPool', ammPool);
                        setAmmPool(ammPool.id);
                        setTokenStatus(TokenStatus.Raydium);
                    } else {
                        setTokenStatus(TokenStatus.Pump);
                    }
                    setTokenMint(mint);
                    setTokenName(name);
                    setTokenSymbol(symbol);
                    setTokenLoaded(true);
                    setBondingCurve(bondingCurve);
                    setAssociatedBondingCurve(associatedBondingCurve);
                }
            } catch (error) {
                toast.error('Invalid mint public key!');
                logMessage(`Error loading token: ${error}`, 'error');
                setTokenName('');
                setTokenSymbol('');
            }
        }
    }

    function removeRow(index: number) {
        setWallets(wallets.filter((_, i) => i !== index));
        setWalletPublicKeys(walletPublicKeys.filter((_, i) => i !== index));
        setBalances(balances.filter((_, i) => i !== index));
        setSolBuys(solBuys.filter((_, i) => i !== index));
        setDelays(delays.filter((_, i) => i !== index));
        setSlippages(slippages.filter((_, i) => i !== index));
        setJitoTips(jitoTips.filter((_, i) => i !== index));
        setRaydiumSellPercent(raydiumSellPercent.filter((_, i) => i !== index));
        setTokenBalances(tokenBalances.filter((_, i) => i !== index));
        setSolValues(solValues.filter((_, i) => i !== index));
    }

    async function buyMultiple(
        connection: Connection,
        indexes: number[],
        mint: string,
        bondingCurve?: string,
        associatedBondingCurve?: string,
        ammPool?: string,

        calculateSlippage = true,
    ) {
        try {
            console.log('indexes', indexes);
            if (mint === '') {
                return toast.error('Token mint is required');
            }

            const allJitoTrasnsactions: VersionedTransaction[] = [];
            for (const [i, index] of indexes.entries()) {
                console.log('bondingCurve', bondingCurve);
                const associatedTokenPDA = findAssociatedTokenAddress(wallets[index].publicKey, new PublicKey(mint));
                console.log('associatedTokenPDA', associatedTokenPDA.toBase58());
                console.log(mint, bondingCurve, associatedBondingCurve);

                let associatedTokenAccountInstructions: TransactionInstruction[] = [];
                let buyInstructions: TransactionInstruction[] = [];
                if (ammPool) {
                    buyInstructions = await getSwapInstructions(
                        connection,
                        wallets[index].publicKey,
                        new PublicKey(WSOL.mint),
                        new PublicKey(mint),
                        new PublicKey(ammPool),
                        solBuys[index] * LAMPORTS_PER_SOL,
                        1,
                    );
                } else if (bondingCurve && associatedBondingCurve) {
                    associatedTokenAccountInstructions = await getCreateAssociatedTokenAccountInstruction(
                        connection,
                        wallets[index],
                        new PublicKey(mint),
                    );
                    buyInstructions = [
                        await getBuyInstructions(
                            connection,
                            new MyWallet(wallets[index]),
                            new PublicKey(mint),
                            new PublicKey(bondingCurve),
                            new PublicKey(associatedBondingCurve),
                            associatedTokenPDA,
                            solBuys[index],
                            slippages[index],
                            calculateSlippage,
                        ),
                    ];
                } else {
                    logMessage('Invalid pool!', 'error');
                    console.log('Invalid pool!');
                    return toast.error('Invalid pool!');
                }
                console.log('buyInstruction', buyInstructions);

                const blockHash = (await connection.getLatestBlockhash()).blockhash;
                const transactionPromises = [];
                if (jitoEnabled) {
                    async function sendToJito() {
                        const priorityFeeInstructions = ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: jitoPriorityFee * lamportsToSolTipForSwapJito,
                        });
                        const tipInstructions = [];
                        if (i % 5 === 0) {
                            tipInstructions.push(getTipInstructions(wallets[index], jitoTips[index] * LAMPORTS_PER_SOL));
                        }
                        const message = new TransactionMessage({
                            payerKey: wallets[index].publicKey,
                            recentBlockhash: blockHash,
                            instructions: [
                                ...associatedTokenAccountInstructions,
                                priorityFeeInstructions,
                                ...buyInstructions,
                                ...tipInstructions,
                            ],
                        }).compileToV0Message();

                        const transaction = new VersionedTransaction(message);
                        transaction.sign([wallets[index]]);
                        allJitoTrasnsactions.push(transaction);

                        if (allJitoTrasnsactions.length >= 5 || i === indexes.length - 1) {
                            const id = await sendBundle(connection, allJitoTrasnsactions);
                            toast.success(`Bundle sent with id: ${id}`);
                            logMessage(`Bundle sent with id: ${id}`, 'info', 'jito', id);
                            allJitoTrasnsactions.length = 0;
                        }
                    }

                    transactionPromises.push(sendToJito());
                }
                if (rpcTxEnabled) {
                    async function sendToRpc() {
                        const priorityFeeInstructions = ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: rpcTxPriorityFee * lamportsToSolTipForSwapRPC,
                        });
                        const message = new TransactionMessage({
                            payerKey: wallets[index].publicKey,
                            recentBlockhash: blockHash,
                            instructions: [...associatedTokenAccountInstructions, priorityFeeInstructions, ...buyInstructions],
                        }).compileToV0Message();

                        const transaction = new VersionedTransaction(message);
                        transaction.sign([wallets[index]]);
                        const result = await connection.simulateTransaction(transaction, { commitment: 'confirmed' });
                        console.log('result', result);
                        const sign = await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true });
                        toast.success(`Transaction sent: ${sign}`);
                        logMessage(`Transaction sent: ${sign}`, 'info', 'solscan', sign);
                        console.log(`Transaction sent: ${sign}`);
                    }
                    transactionPromises.push(sendToRpc());
                }
            }
        } catch (error) {
            console.log(`Error buying token, ${error}`);
            toast.error('Error buying token');
            logMessage(`Error buying token: ${error}`, 'error');
        }
    }

    async function sellMultiple(
        connection: Connection,
        indexes: number[],
        mint: string,
        percents: number[],
        _bondingCurve?: string,
        _associatedBondingCurve?: string,
        _ammPool?: string,
    ) {
        try {
            if (mint === '') {
                return toast.error('Token mint is required');
            }
            const allJitoTrasnsactions: VersionedTransaction[] = [];
            for (const [i, index] of indexes.entries()) {
                if (percents[i] === 0) continue;

                const keypair = wallets[index];
                const associatedTokenPDA = findAssociatedTokenAddress(keypair.publicKey, new PublicKey(mint));
                let balance: number = 0;
                try {
                    balance = Number((await connection.getTokenAccountBalance(associatedTokenPDA, 'processed')).value.amount);
                } catch (e) {
                    console.log(`Failed getting balance:  ${e}`);
                }
                if (balance === 0) continue;
                console.log('bonding', bondingCurve);
                let sellInstruction: TransactionInstruction[] = [];
                if (_ammPool) {
                    sellInstruction = await getSwapInstructions(
                        connection,
                        keypair.publicKey,
                        new PublicKey(mint),
                        new PublicKey(WSOL.mint),
                        new PublicKey(_ammPool),
                        Math.floor((balance * percents[i]) / 100),
                        1,
                    );
                } else if (_bondingCurve && _associatedBondingCurve) {
                    sellInstruction = [
                        await getSellInstruction(
                            connection,
                            new MyWallet(keypair),
                            new PublicKey(mint),
                            new PublicKey(_bondingCurve),
                            new PublicKey(_associatedBondingCurve),
                            associatedTokenPDA,
                            Math.floor((balance * percents[i]) / 100),
                            slippages[index],
                        ),
                    ];
                }

                const blockHash = (await connection.getLatestBlockhash()).blockhash;
                const transactionPromises = [];
                if (jitoEnabled) {
                    async function sendToJito() {
                        const priorityFeeInstructions = ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: jitoPriorityFee * lamportsToSolTipForSwapJito,
                        });
                        const tipInstructions = [];
                        if (i % 5 === 0) {
                            tipInstructions.push(getTipInstructions(wallets[index], jitoTips[index] * LAMPORTS_PER_SOL));
                        }
                        const message = new TransactionMessage({
                            payerKey: keypair.publicKey,
                            recentBlockhash: blockHash,
                            instructions: [priorityFeeInstructions, ...sellInstruction, ...tipInstructions],
                        }).compileToV0Message();

                        const transaction = new VersionedTransaction(message);
                        transaction.sign([keypair]);
                        allJitoTrasnsactions.push(transaction);

                        if (allJitoTrasnsactions.length >= 5 || i === indexes.length - 1) {
                            const id = await sendBundle(connection, allJitoTrasnsactions);
                            toast.success(`Bundle sent with id: ${id}`);
                            logMessage(`Bundle sent with id: ${id}`, 'info', 'jito', id);
                            allJitoTrasnsactions.length = 0;
                        }
                    }

                    transactionPromises.push(sendToJito());
                }
                if (rpcTxEnabled) {
                    async function sendToRpc() {
                        const priorityFeeInstructions = ComputeBudgetProgram.setComputeUnitPrice({
                            microLamports: rpcTxPriorityFee * lamportsToSolTipForSwapRPC,
                        });
                        const message = new TransactionMessage({
                            payerKey: keypair.publicKey,
                            recentBlockhash: blockHash,
                            instructions: [priorityFeeInstructions, ...sellInstruction],
                        }).compileToV0Message();

                        const transaction = new VersionedTransaction(message);
                        transaction.sign([keypair]);
                        const sign = await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true });
                        toast.success(`Transaction sent: ${sign}`);
                        logMessage(`Transaction sent: ${sign}`, 'info', 'solscan', sign);
                        console.log(`Transaction sent: ${sign}`);
                    }
                    transactionPromises.push(sendToRpc());
                }
            }
        } catch (error) {
            toast.error(`Error selling token, ${error}`);
            console.log(`Error selling token, ${error}`);
        }
    }

    async function handlePurchaseWithDelay(
        connection: Connection,
        index: number,
        mint: string,
        bondingCurve?: string,
        associatedBondingCurve?: string,
        ammPool?: string,
        calculateSlippage = true,
    ) {
        if (delays[index] < 0) return;
        await new Promise((resolve) => setTimeout(resolve, delays[index]));
        calculateSlippage = calculateSlippage && delays[index] === 0 ? true : false;
        buyMultiple(connection, [index], mint, bondingCurve, associatedBondingCurve, ammPool, calculateSlippage);
    }

    async function startMultiPurchases(
        connection: Connection,
        mint: string,
        bondingCurve?: string,
        associatedBondingCurve?: string,
        ammPool?: string,
        calculateSlippage = true,
    ) {
        toast.info('Starting purchases');
        for (const [index] of wallets.entries()) {
            handlePurchaseWithDelay(connection, index, mint, bondingCurve, associatedBondingCurve, ammPool, calculateSlippage);
        }
        console.log('All purchases started');
    }

    async function scanForDeployment(connection: Connection, deployer: string) {
        try {
            if (scanStarted) return toast.error('Scan already started');
            toast.info('Scan started');
            setScanStarted(true);
            let lastTransaction = (
                await connection.getSignaturesForAddress(new PublicKey(deployer), { limit: 1 }, 'confirmed')
            )[0].signature;

            while (true) {
                try {
                    const currentLatestSignature = (
                        await connection.getSignaturesForAddress(new PublicKey(deployer), { limit: 1 }, 'confirmed')
                    )[0].signature;

                    if (lastTransaction !== currentLatestSignature) {
                        toast.info(`New transaction detected ${currentLatestSignature}`);
                        logMessage(
                            `New transaction detected ${currentLatestSignature}`,
                            'info',
                            'solscan',
                            currentLatestSignature,
                        );
                        const transaction = await connection.getTransaction(currentLatestSignature, {
                            commitment: 'confirmed',
                            maxSupportedTransactionVersion: 0,
                        });
                        if (transaction) {
                            const keys = transaction!.transaction.message
                                .getAccountKeys()
                                .staticAccountKeys.map((key) => key.toBase58());
                            if (
                                (keys.length === 16 || keys.length === 18) &&
                                (keys[11] === MPL_TOKEN_METADATA || keys[13] === MPL_TOKEN_METADATA)
                            ) {
                                console.log('Deployed');
                                const mint = keys[1];
                                const bundingCurve = keys[3];
                                const associatedBundingCurve = keys[4];
                                console.log(
                                    `mint: ${mint} bundingCurve: ${bundingCurve} associatedBundingCurve: ${associatedBundingCurve}`,
                                );
                                toast.success(`Deployment detected, mint: ${mint}`);
                                logMessage(`Deployment detected, mint: ${mint}`, 'info');

                                setTokenStatus(TokenStatus.Pump);
                                setTokenMint(mint);
                                setTokenLoaded(true);
                                setBondingCurve(bondingCurve);
                                setAssociatedBondingCurve(associatedBondingCurve);
                                loadToken(connection, mint);

                                startMultiPurchases(connection, mint, bundingCurve, associatedBundingCurve, ammPool);
                            }
                        }
                        lastTransaction = currentLatestSignature;
                    }
                    await new Promise((resolve) => setTimeout(resolve, 100));
                } catch (error) {
                    toast.error(`Error scanning for deployment: ${error}`);
                }
            }
        } catch (error) {
            console.log(`Error scanning for deployment: ${error}`);
            toast.error(`Error scanning for deployment: ${error}`);
            setScanStarted(false);
        }
    }

    return (
        <section className={BuySellCSS.buySellContainer}>
            <div className={BuySellCSS.header}>
                <div className={BuySellCSS.headerInfoWrap}>
                    <div className={BuySellCSS.headerInfo}>
                        <label>
                            <b>Mode</b>
                            <select value={mode} onChange={modeChange}>
                                {availableModes.map((mode, index) => (
                                    <option value={mode} key={index}>
                                        {mode}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    {tokenLoaded && mode === availableModes[0] ? (
                        <div className={BuySellCSS.headerLabel}>
                            <b className={BuySellCSS.tokenDetected}>Detected Token:</b>
                            <span>{sliceWalletAddress(tokenMint, 8)}</span>
                            <button
                                className={isTokenMintCopied ? 'copiedIcon' : 'copyIcon'}
                                onClick={() => copyTokenMint(tokenMint)}
                            ></button>
                            <button
                                className='buttonAccentGreen'
                                onClick={() => {
                                    setTokenStatus(TokenStatus.NotLoaded);
                                    setTokenMint('');
                                    setTokenLoaded(false);
                                    setBondingCurve('');
                                    setAssociatedBondingCurve('');
                                    setAmmPool('');
                                    setCompleteCurveDollar('0');
                                    setCompleteCurvePercent('0');
                                }}
                            >
                                X
                            </button>
                        </div>
                    ) : (
                        <>
                            {mode === availableModes[0] && (
                                <div className={BuySellCSS.headerLabel}>
                                    <b>Target Token:</b>
                                    <input type='text' value={tokenMint} onChange={tokenMintChange} />
                                    <button className='buttonAccentGreen' onClick={() => loadToken(connection, tokenMint)}>
                                        SET
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {mode === availableModes[1] && (
                        <>
                            {scanStarted && !tokenLoaded ? (
                                <div className={BuySellCSS.headerLabel}>
                                    <b>
                                        Target <br />
                                        Token/Wallet
                                    </b>
                                    <span>{sliceWalletAddress(walletToScan, 8)}</span>
                                    <button
                                        className={isWalletToScanCopied ? 'copiedIcon' : 'copyIcon'}
                                        onClick={() => copyWalletToScan(walletToScan)}
                                    ></button>
                                    <button
                                        onClick={() => {
                                            setScanStarted(false);
                                        }}
                                        className='buttonAccentRed'
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            ) : (
                                <>
                                    {!tokenLoaded && (
                                        <div className={BuySellCSS.headerLabel}>
                                            <b>Target Wallet:</b>
                                            <input
                                                onChange={walletToScanChange}
                                                value={walletToScan}
                                                placeholder='Wallet to scan'
                                            />
                                            <button
                                                disabled={!walletToScan}
                                                className='buttonAccentGreen'
                                                onClick={() => {
                                                    scanForDeployment(connection, walletToScan);
                                                }}
                                            >
                                                SNIPE
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {tokenLoaded && (
                                <div className={BuySellCSS.headerLabel}>
                                    <b className={BuySellCSS.tokenDetected}>Detected Token:</b>
                                    <span>{sliceWalletAddress(tokenMint, 8)}</span>
                                    <button
                                        className={isTokenMintCopied ? 'copiedIcon' : 'copyIcon'}
                                        onClick={() => copyTokenMint(tokenMint)}
                                    ></button>
                                    <button
                                        className='buttonAccentGreen'
                                        onClick={() => {
                                            setTokenStatus(TokenStatus.NotLoaded);
                                            setTokenMint('');
                                            setTokenLoaded(false);
                                            setScanStarted(false);
                                        }}
                                    >
                                        X
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {mode === availableModes[2] && (
                        <button
                            className='buttonAccentGreen'
                            onClick={() => {
                                scanForDeployment(connection, deployers[0].publicKey.toBase58());
                            }}
                            disabled={deployers.length === 0 || scanStarted}
                        >
                            START DEPLOYER SCANNING
                        </button>
                    )}
                    <div className={BuySellCSS.scenarioPurchases}>
                        <button
                            onClick={() => {
                                startMultiPurchases(connection, tokenMint, bondingCurve, associatedBondingCurve, ammPool);
                            }}
                        >
                            Start Scenario Purchases
                        </button>
                        <button
                            className='buttonRaydiumSnipe'
                            disabled={snipeSellOnRaydiumStarted || tokenStatus === 'Not Loaded' || tokenStatus === 'Raydium'}
                            onClick={() => setSnipeSellOnRaydiumStarted(true)}
                        >
                            Sell On Raydium Detection
                        </button>
                    </div>
                </div>
                <div className={BuySellCSS.buttonWrap}>
                    <button
                        className={tokenStatus === 'Raydium' ? 'buttonRaydiumBuy' : 'buttonAccentGreen'}
                        onClick={() => {
                            buyMultiple(
                                connection,
                                walletSelectedBuy.map((value, index) => (value ? index : -1)).filter((index) => index !== -1),
                                tokenMint,
                                bondingCurve,
                                associatedBondingCurve,
                                ammPool,
                            );
                        }}
                        disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                    >
                        BUY ALL ✅︎
                    </button>
                </div>
                <div className={BuySellCSS.pumpStatusBlock}>
                    <p className={BuySellCSS.statusHeadline}>
                        Token Status:{' '}
                        <span className={`${tokenStatus === 'Raydium' ? BuySellCSS.raydiumStatus : ''}`}>{tokenStatus}</span>
                    </p>
                    {tokenStatus === 'Pump' && (
                        <>
                            <p className={BuySellCSS.lpInfo}>
                                <b>LP:</b>
                                {} SOL
                            </p>
                            <label className={BuySellCSS.progressInfoWrap}>
                                <span className={BuySellCSS.progressInfo}>
                                    {completeCurvePercent}%/${completeCurveDollar}
                                </span>
                                <progress max='100' value={completeCurvePercent} />
                            </label>
                            <div className={BuySellCSS.statusFooter}>
                                <div>
                                    <p>
                                        Init Balance {totalInitBalance.toFixed(2)} ($
                                        {(totalInitBalance * solPrice).toFixed(2)})
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Current Balance {totalSolBalance.toFixed(2)} ($
                                        {(totalSolBalance * solPrice).toFixed(2)})
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={BuySellCSS.buttonGroup}>
                    <p className={BuySellCSS.verticalText}>SELL ALL ✅︎</p>
                    <button
                        className={tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}
                        onClick={() => {
                            sellMultiple(
                                connection,
                                walletSelectedSell.map((value, index) => (value ? index : -1)).filter((index) => index !== -1),
                                tokenMint,
                                new Array(walletSelectedSell.length).fill(100),
                                bondingCurve,
                                associatedBondingCurve,
                                ammPool,
                            );
                        }}
                        disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                    >
                        100%
                    </button>
                    <button
                        className={tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}
                        onClick={() => {
                            sellMultiple(
                                connection,
                                walletSelectedSell.map((value, index) => (value ? index : -1)).filter((index) => index !== -1),
                                tokenMint,
                                new Array(walletSelectedSell.length).fill(50),
                                bondingCurve,
                                associatedBondingCurve,
                                ammPool,
                            );
                        }}
                        disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                    >
                        50%
                    </button>
                    <div>
                        <input
                            type='number'
                            value={customSellValueAll}
                            onChange={customSellValueAllChange}
                            disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                        />
                        <button
                            className={tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}
                            onClick={() => {
                                sellMultiple(
                                    connection,
                                    walletSelectedSell
                                        .map((value, index) => (value ? index : -1))
                                        .filter((index) => index !== -1),
                                    tokenMint,
                                    new Array(walletSelectedSell.length).fill(customSellValueAll),
                                    bondingCurve,
                                    associatedBondingCurve,
                                    ammPool,
                                );
                            }}
                            disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                        >
                            %
                        </button>
                    </div>
                </div>
            </div>
            <table className={BuySellCSS.buySellTable}>
                <thead>
                    <tr>
                        <th colSpan={3}></th>
                        <th>Wallets</th>
                        <th>Balance</th>
                        <th>SOL Buy</th>
                        <th>Delay(ms)</th>
                        <th>Slippage</th>
                        <th>JitoTip</th>
                        <th>
                            <input
                                className={tokenStatus === 'Raydium' ? 'raydiumBuyInput' : 'greenInput'}
                                type='checkbox'
                                checked={selectAllBuy}
                                onChange={selectAllBuyChange}
                                disabled={!wallets.length || tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                            />
                        </th>
                        <th>Buy</th>
                        <th>Raydium Sell(%)</th>
                        <th>Tokens</th>
                        <th>SOLVal.</th>
                        <th colSpan={3}>Sell Control</th>
                        <th>
                            <input
                                className={tokenStatus === 'Raydium' ? 'raydiumSellInput' : 'yellowInput'}
                                type='checkbox'
                                checked={selectAllSell}
                                onChange={selectAllSellChange}
                                disabled={!wallets.length || tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {walletPublicKeys.map((wallet, index) => (
                        <tr key={index} className={allWalletRoles[index].includes('deployer') ? BuySellCSS.deployerRow : ''}>
                            <td>
                                <button
                                    className={`${BuySellCSS.tableIcon} ${BuySellCSS.removeIcon}`}
                                    onClick={() => removeRow(index)}
                                ></button>
                            </td>
                            <td>
                                <a
                                    href={`https://solscan.io/account/${wallets[index].publicKey.toBase58()}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <button className={`${BuySellCSS.tableIcon} ${BuySellCSS.solScanIcon}`}></button>
                                </a>
                            </td>
                            <td>
                                <button className={`${BuySellCSS.tableIcon} copyIcon`} onClick={() => copyToClipboard(wallet)}>
                                    <span className='copyButtonActiveEffect'></span>
                                </button>
                            </td>
                            <td>
                                <p className={BuySellCSS.walletAddress}>
                                    {`${wallet.substring(0, 5)}...${wallet.substring(wallet.length - 5)}`}
                                    <span className={BuySellCSS.deployerIcon}>
                                        {allWalletRoles[index].includes('deployer') && '🤵‍♂️'}
                                    </span>
                                </p>
                            </td>
                            <td>{(balances[index] / LAMPORTS_PER_SOL).toFixed(2)}</td>
                            <td>
                                <input
                                    className={`${BuySellCSS.customBuyInput} ${BuySellCSS.customBuyInputAccent} ${
                                        solBuys[index] > balances[index] / LAMPORTS_PER_SOL ? BuySellCSS.insufficientBalance : ''
                                    }`}
                                    type='number'
                                    value={solBuys[index]}
                                    onChange={solBuysChange(index)}
                                />
                            </td>
                            <td>
                                <input
                                    className={BuySellCSS.customBuyInput}
                                    type='number'
                                    value={delays[index]}
                                    onChange={delaysChange(index)}
                                />
                            </td>
                            <td>
                                <input
                                    className={BuySellCSS.customBuyInput}
                                    type='number'
                                    value={slippages[index]}
                                    onChange={slippagesChange(index)}
                                />
                            </td>
                            <td>
                                <input
                                    className={BuySellCSS.customBuyInput}
                                    type='number'
                                    value={jitoTips[index]}
                                    onChange={jitoTipsChange(index)}
                                />
                            </td>
                            <td>
                                <input
                                    type='checkbox'
                                    checked={walletSelectedBuy[index]}
                                    onChange={walletSelectedBuyChange(index)}
                                    disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                />
                            </td>
                            <td>
                                <button
                                    className={tokenStatus === 'Raydium' ? 'buttonRaydiumBuy' : 'buttonAccentGreen'}
                                    onClick={() => {
                                        buyMultiple(
                                            connection,
                                            [index],
                                            tokenMint,
                                            bondingCurve,
                                            associatedBondingCurve,
                                            ammPool,
                                        );
                                    }}
                                    disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                >
                                    BUY
                                </button>
                            </td>
                            <td>
                                <input
                                    min={0}
                                    max={100}
                                    className={BuySellCSS.customBuyInput}
                                    type='number'
                                    value={raydiumSellPercent[index] !== undefined ? raydiumSellPercent[index] : 100}
                                    onChange={raydiumSellPercentChange(index)}
                                />
                            </td>
                            <td>{(tokenBalances[index] / 1e6).toLocaleString()}</td>
                            <td>
                                {solValues[index] ? (
                                    <p className={BuySellCSS.solProfit}>+{solValues[index].toFixed(4)}</p>
                                ) : (
                                    <p className={BuySellCSS.noSolProfit}>{solValues[index].toFixed(4)}</p>
                                )}
                            </td>
                            <td>
                                <button
                                    className={`${BuySellCSS.sellButton} ${tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}`}
                                    onClick={() => {
                                        sellMultiple(
                                            connection,
                                            [index],
                                            tokenMint,
                                            [100],
                                            bondingCurve,
                                            associatedBondingCurve,
                                            ammPool,
                                        );
                                    }}
                                    disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                >
                                    100%
                                </button>
                            </td>
                            <td>
                                <button
                                    className={`${BuySellCSS.sellButton} ${tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}`}
                                    onClick={() => {
                                        sellMultiple(
                                            connection,
                                            [index],
                                            tokenMint,
                                            [50],
                                            bondingCurve,
                                            associatedBondingCurve,
                                            ammPool,
                                        );
                                    }}
                                    disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                >
                                    50%
                                </button>
                            </td>
                            <td>
                                <div className={BuySellCSS.customSellInputWrap}>
                                    <input
                                        className={BuySellCSS.customSellInput}
                                        type='number'
                                        min={0}
                                        max={100}
                                        value={customSellValue[index] || defaultSellPercent}
                                        onChange={customSellValueChange(index)}
                                        disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                    />
                                    <button
                                        className={tokenStatus === 'Raydium' ? 'buttonRaydiumSell' : ''}
                                        onClick={() => {
                                            sellMultiple(
                                                connection,
                                                [index],
                                                tokenMint,
                                                [customSellValue[index]],
                                                bondingCurve,
                                                associatedBondingCurve,
                                                ammPool,
                                            );
                                        }}
                                        disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                    >
                                        %
                                    </button>
                                </div>
                            </td>
                            <td>
                                <input
                                    type='checkbox'
                                    checked={walletSelectedSell[index]}
                                    onChange={walletSelectedSellChange(index)}
                                    disabled={tokenStatus === 'Not Loaded' || tokenStatus === 'Curve Completed'}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
                {wallets.length > 0 && (
                    <tfoot className={BuySellCSS.tableFooter}>
                        <tr>
                            <td colSpan={4}></td>
                            <td>{totalSolBalance.toFixed(2)}</td>
                            <td className={totalSolBuy > totalSolBalance ? BuySellCSS.insufficientSolBalance : ''}>
                                {totalSolBuy}
                            </td>
                            <td colSpan={6}></td>
                            <td>
                                {totalTokenBalance / 1e6} <br />
                                <span>{supplyOwnPercent.toFixed(2)}% of Supply</span>
                            </td>
                            <td colSpan={5}>
                                {totalSolValues ? (
                                    <>
                                        Total Token Value: <span className={BuySellCSS.solProfit}>{totalSolValues} SOL</span>
                                    </>
                                ) : (
                                    <>
                                        Total Token Value: {''}
                                        <span className={BuySellCSS.noSolProfit}>{totalSolValues} SOL</span>
                                    </>
                                )}
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </section>
    );
}
