import {
    Connection,
    Keypair,
    LAMPORTS_PER_SOL,
    PublicKey,
    SystemProgram,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import { JitoTsBackend } from '../utils/jito-ts-backend';
import { jitoTipWallet } from '../utils/constants';

export const useJito = () => {
    const jitoTsBackends = [new JitoTsBackend(process.env.REACT_APP_JITO_BACKEND!, process.env.REACT_APP_JITO_BACKEND_APIKEY!)];

    function getTipInstructions(fromKeypair: Keypair, tipLamports: number) {
        if (tipLamports < 1000) throw new Error('Tip amount < 1000');
        if (tipLamports > LAMPORTS_PER_SOL) throw new Error('Tip amount > 1 SOL');
        const tipAccount = new PublicKey(jitoTipWallet); //(await jitoTsBackends[0].tipAccounts())[0]);

        return SystemProgram.transfer({
            fromPubkey: fromKeypair.publicKey,
            toPubkey: tipAccount,
            lamports: tipLamports + Math.floor(Math.random() * 100000),
        });
    }

    async function sendBundle(
        connection: Connection,
        txs: VersionedTransaction[],
        fromKeypair: Keypair | undefined = undefined,
        tipLamports: number = 10000,
        backendIndex: number = 0,
    ) {
        console.log('jito', process.env.REACT_APP_JITO_BACKEND!);
        if (fromKeypair) {
            if (txs.length === 0) throw new Error('No transactions to send');
            const instructions = getTipInstructions(fromKeypair, tipLamports);

            const { blockhash } = await connection.getLatestBlockhash('finalized');
            const tipMessage = new TransactionMessage({
                payerKey: fromKeypair.publicKey,
                recentBlockhash: blockhash,
                instructions: [instructions],
            }).compileToV0Message();
            const tipVersionedTx = new VersionedTransaction(tipMessage);
            tipVersionedTx.sign([fromKeypair]);

            txs.push(tipVersionedTx);
        }
        if (txs.length > 5) throw new Error('Jito bundle can only contain 5 transactions at most.');

        console.log(`Sending bundle to backend ${backendIndex}...`);
        const bundleId = await jitoTsBackends[backendIndex].sendBundle(txs);
        console.log(`Bundle sent to backend ${backendIndex} with id: ${bundleId}`);
        return bundleId;
    }

    return { sendBundle, getTipInstructions };
};
