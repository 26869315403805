import { PublicKey } from '@solana/web3.js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { BN } from '@coral-xyz/anchor';

export interface BondingCurveMetadata {
    virtualTokenReserves: BN;
    virtualSolReserves: BN;
    realTokenReserves: BN;
    realSolReserves: BN;
    tokenTotalSupply: BN;
    complete: boolean;
}

export const DEFAULT_BONDING_CURVE_METADATA: BondingCurveMetadata = {
    virtualTokenReserves: new BN(1073000000000000),
    virtualSolReserves: new BN(30000000000),
    realTokenReserves: new BN(793100000000000),
    realSolReserves: new BN(0),
    tokenTotalSupply: new BN(1000000000000000),
    complete: false,
};

export const EVENT_AUTHORITY_PUBKEY = new PublicKey('Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1');

export const PROGRAM_PUBKEY = new PublicKey('6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P');

export const FEE_RECIPIENT_PUBKEY = new PublicKey('CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM');

export const MPL_TOKEN_METADATA_PUBKEY = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

export const getBondingCurvePubkey = (mintPubkey: PublicKey) =>
    PublicKey.findProgramAddressSync([Buffer.from('bonding-curve'), mintPubkey.toBuffer()], PROGRAM_PUBKEY)[0];

export const getAssociatedBondingCurvePubkey = (mintPubkey: PublicKey, bondingCurvePubkey: PublicKey) =>
    PublicKey.findProgramAddressSync(
        [bondingCurvePubkey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mintPubkey.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID,
    )[0];

export const MINT_AUTHORITY_PUBKEY = PublicKey.findProgramAddressSync([Buffer.from('mint-authority')], PROGRAM_PUBKEY)[0];

export const getMetadataPubkey = (mintPubkey: PublicKey) =>
    PublicKey.findProgramAddressSync(
        [Buffer.from('metadata'), MPL_TOKEN_METADATA_PUBKEY.toBuffer(), mintPubkey.toBuffer()],
        MPL_TOKEN_METADATA_PUBKEY,
    )[0];

export const GLOBAL_PUBKEY = PublicKey.findProgramAddressSync([Buffer.from('global')], PROGRAM_PUBKEY)[0];
